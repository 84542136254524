import Vue from "vue";
import {
  mdiLogout,
  mdiHelpCircleOutline,
  mdiBrightness4,
  mdiBrightness7,
  mdiHeadphones,
  mdiStopCircleOutline,
} from "@mdi/js";
import llamadaModule from "@/store/modules/llamadaModule";

export default Vue.extend({
  name: "AppToolbarComponent",
  props: {
    leftDrawer: {
      type: Boolean,
      default: () => false,
    },
    rightDrawer: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      Icons: {
        Logout: mdiLogout as string,
        Help: mdiHelpCircleOutline as string,
        LightTheme: mdiBrightness4 as string,
        DarkTheme: mdiBrightness7 as string,
        Headphones: mdiHeadphones as string,
        Stop: mdiStopCircleOutline as string,
      } as Record<string, string>,
      Theme: false as boolean,
    };
  },
  computed: {
    onCall(): boolean {
      return llamadaModule.llamadaEnCurso;
    },
    duracion(): string {
      const d = llamadaModule.duracionLlamada;

      const h = Math.floor(d / 3600);
      const m = Math.floor((d % 3600) / 60);
      const s = Math.floor((d % 3600) % 60);

      //const hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
      const hDisplay = this.padLeft(h.toString(), "0", 2) + ":";
      const mDisplay = this.padLeft(m.toString(), "0", 2) + ":";
      const sDisplay = this.padLeft(s.toString(), "0", 2);
      return hDisplay + mDisplay + sDisplay;
    },
    /*
    atencion(): string {
      return "Cliente en Linea: " + llamadaModule.Ticket.nombre + " " + llamadaModule.Ticket.apellidoPaterno;
    },
    */
  },
  methods: {
    onThemeChange(): void {
      this.$data.Theme = !this.$data.Theme;
      this.$vuetify.theme.dark = this.$data.Theme;
    },
    onLeftBtnClick(): void {
      this.$emit("LeftDrawerClick");
    },
    onRightBtnClick(): void {
      this.$emit("RightDrawerClick");
    },
    Logout(): void {
      this.$store.dispatch("oidcStore/signOutOidc");
    },
    padLeft(text: string, padChar: string, size: number): string {
      return (String(padChar).repeat(size) + text).substr(size * -1, size);
    },
    btnStopCall(): void {
      llamadaModule.PauseCall();
    },
  },
});
