import { render, staticRenderFns } from "./appToolbar.vue?vue&type=template&id=412e51c0"
import script from "./appToolbar.ts?vue&type=script&lang=ts&external"
export * from "./appToolbar.ts?vue&type=script&lang=ts&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports